.contactContainer {
  position: relative;
  height: 100%;
  width: 100%;
}

.initialContent {
  background-color: #ffeedd;
  z-index: 1;
  position: relative;
  height: 100vh;
}

.contactContent {
  background-color: #292929;
  z-index: 1;
  position: relative;
  height: 100vh;
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  animation-name: slideUpAnimation; /* Reference the keyframe animation */
  animation-duration: 1s; /* Animation duration */
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0, 0, 0.25, 1.3);
  z-index: 2;
}

.wrapper.animate {
  height: 0vh;
}

.lightWrapper {
  background-color: #ffeedd;
}

@keyframes slideUpAnimation {
  0% {
    height: 100vh; /* Start with full height */
  }
  5% {
    height: 99.5vh; /* Slightly slower start */
  }
  10% {
    height: 99vh; /* Gradual acceleration */
  }
  15% {
    height: 98.5vh; /* Faster acceleration */
  }
  20% {
    height: 98vh; /* Even faster acceleration */
  }
  25% {
    height: 97.5vh;
  }
  30% {
    height: 97vh;
  }
  100% {
    height: 0vh; /* End with zero height */
  }
}

.navBar {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  font-size: 0.6rem;
  font-weight: 600;
  letter-spacing: 4px;
}

.homeLink {
  color: white;
  text-decoration: none;
  padding-left: 1rem;
  padding-right: 1rem;
}

.contactLine {
  width: 4rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.25rem;
  background-color: white;
  height: 0.15rem;
}

.contactCopywrightContainer {
  padding-left: 1.2rem;
  padding-right: 1rem;
  color: white;
}

.contactCopywright {
  color: white;
  font-weight: normal;
}

.contactText {
  margin-bottom: 1rem;
  padding: 1.5rem;
}

.contactHeader {
  color: white;
  letter-spacing: 3px;
  font-size: 3.5rem;
  font-family: 'Fondamento', cursive;
  margin-bottom: 0;
}

.contactParagraph {
  font-size: 0.8rem;
  color: white;
  letter-spacing: 1px;
}

.underline {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .contactContent {
    height: 100vh;
  }

  .navBar {
    flex-direction: column;
    height: 100vh;
    position: fixed;
    width: 6%;
    justify-content: space-around;
    align-items: center;
  }

  .homeLink,
  .contactLine,
  .contactCopywrightContainer {
    margin-bottom: 2rem;
    width: 4rem;
    transform: rotate(-90deg);
  }

  .contactLine {
    width: 8rem;
  }

  .contactText {
    margin-left: 6rem;
    margin-right: 6rem;
    padding-top: 8rem;
  }

  .contactHeader {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1024px) {
  .contactHeader {
    font-size: 9rem;
  }
}

@media screen and (min-width: 1440px) {
  .contactHeader {
    font-size: 14rem;
    margin-top: 0;
  }

  .contactText {
    margin-left: 12rem;
    min-width: 50vw;
    width: 50vw;
  }
}

@media screen and (min-width: 2560px) {
  .contactHeader {
    font-size: 20rem;
  }
}
